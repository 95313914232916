<template>
    <label class="cr-radio" :class="radioClasses">
        <input class="cr-radio__input" :value="value" type="radio" @change="change" :checked="isChecked" />
        <div class="cr-radio__box">
            <div class="cr-radio__icon" :class="{ 'cr-radio__icon_checked': isChecked }" />
        </div>
        <div class="cr-radio__text" :class="{ 'cr-radio__text_checked': isChecked }">
            <slot />
        </div>
        <div v-if="isBox && !$slots['content']" class="cr-radio_box__text__wrapper">
            <span>{{ title }}</span>
            {{ description }}
        </div>
        <div class="cr-radio_box__text__wrapper" v-if="isBox && $slots['content']">
            <slot name="content" />
        </div>
    </label>
</template>

<script>
    import { isEqual } from 'lodash';

    export default {
        name: 'CRRadio',
        props: {
            modelValue: {
                type: [String, Number, Object],
                default: '',
            },
            value: {
                type: [String, Number, Object],
                default: '',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            isBox: {
                type: Boolean,
                default: false,
            },
            title: {
                type: String,
                default: '',
            },
            description: {
                type: String,
                default: '',
            },
        },
        emits: ['update:modelValue', 'change'],
        computed: {
            isChecked() {
                return isEqual(this.modelValue, this.value);
            },
            radioClasses() {
                return [
                    this.disabled && `cr-radio_disabled`,
                    this.isChecked && `cr-radio_checked`,
                    this.isBox && `cr-radio_box`,
                    this.isBox && this.$slots['content'] && `cr-radio_content`,
                ];
            },
        },
        methods: {
            change() {
                this.$emit('update:modelValue', this.value);
                this.$emit('change', this.value);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .cr-radio {
        color: $white;
        display: flex;
        cursor: pointer;

        &:hover {
            .cr-radio__box {
                border-color: $primary;
            }
        }
    }

    .cr-radio__input {
        display: none;
    }

    .cr-radio__box {
        @include row-align-center-justify-center;

        min-width: 18px;
        height: 18px;

        border: 2px solid $primary;
        border-radius: $br-50;

        padding: 3px;
    }

    .cr-radio__icon {
        display: none;
        background: $primary;
        border-radius: $br-50;
        height: 100%;
        width: 100%;
    }

    .cr-radio__text {
        padding-left: 10px;
        color: $black;
        font-weight: 400;
        font-size: $font-16;
    }

    .cr-radio__text_checked {
        font-weight: 700;
    }

    .cr-radio__icon_checked {
        display: block;
    }

    // cr-radio_box
    .cr-radio_box {
        @include row-align-start-justify-between;

        padding: 10px 16px;

        background: $white;
        box-shadow: $box-shadow-dark;
        border-radius: $br-10;
        border: 2px solid transparent;

        transition: all 0.2s linear;

        .cr-radio__box {
            margin-top: 2px;
        }

        .cr-radio_box__text__wrapper {
            color: $black;
            width: 95%;

            span {
                font-weight: 700;
            }
        }

        &.cr-radio_checked {
            background: $primary-500;
            border: 2px solid $primary;
            box-shadow: none;
        }
    }

    // cr-radio_content

    .cr-radio_content {
        @include row-align-center-justify-start;

        flex-wrap: wrap;

        .cr-radio_box__text__wrapper {
            margin-top: 10px;
            width: 100%;
        }
    }
</style>
